/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import AppFormMain from '@/components/Form/Main/AppFormMain'
import AppFormReview from '@/components/Form/Review/AppFormReview'
import AppFormPopupDelivery from '@/components/Form/Popup/AppFormPopupDelivery'
import AppFormPopupProduct from '@/components/Form/Popup/AppFormPopupProduct'
import AppFormPopupService from '@/components/Form/Popup/AppFormPopupService'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from './assets/js/Swiper/Swiper'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import FaqItem from '@/assets/js/Faq/FaqItem'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import HeaderFixed from '@/assets/js/Header/HeaderFixed/HeaderFixed'
import MenuProduct from '@/assets/js/Menu/MenuProduct/MenuProduct'
import RefMetrika from '@/assets/js/Ref/Ref'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new SwiperSlider()
    new ShowHidden()
    new FaqItem()
    new MenuMobile()
    new HeaderMobile()
    new HeaderFixed()
    new MenuProduct()
    new RefMetrika()

    if(document.getElementById('form--main') != null) {
        createApp(AppFormMain).use(form).use(Maska).mount('#form--main')
    }

    if(document.getElementById('form--review') != null) {
        createApp(AppFormReview).use(form).mount('#form--review')
    }

    if(document.getElementById('form--delivery') != null) {
        createApp(AppFormPopupDelivery).use(form).use(Maska).mount('#form--delivery')
    }

    if(document.getElementById('form--product') != null) {
        createApp(AppFormPopupProduct).use(form).use(Maska).mount('#form--product')
    }

    if(document.getElementById('form--service') != null) {
        createApp(AppFormPopupService).use(form).use(Maska).mount('#form--service')
    }
})

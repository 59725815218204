<template lang="pug">
.form-review
  .form-review__fields
    .form-review__field
      label.input-text(:class="{'input-text-focus': focusedName}")
        svg.input-text__icon
          use(xlink:href="#form-field-name")
        input.input-text__input(
          type="text"
          placeholder="Ваше имя"
          v-model="fieldName"
          @focus="focusedName = true"
          @blur="focusedName = false"
        )
    .form-review__field
      label.input-textarea
        textarea.input-textarea__input(
          placeholder="Ваш отзыв"
          v-model="fieldText"
        )

  p.center.size-small-640(v-html="settingConsent.text2")

  .form-review__button
    .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
      .button__name(v-html="settings.button")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormReview',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      settingConsent: {},
      send: false,
      fieldName: '',
      focusedName: false,
      fieldText: '',
      files: ''
    }
  },
  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        $('.button-popup--success').click()
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Отзыв"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Отзыв клиента',
              value: this.fieldText
            },
            {
              title: 'ID',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldText = ''

        setTimeout(() => {
          this.send = !this.send
          $('.is-close').click()
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldText: {
        required,
        minLength: minLength(10)
      }
    }
  },

  created () {
    this.settings = window.appBioEkoGrung.FormPopupReview
    this.settingConsent = window.appBioEkoGrung.FormPopupConsent
  }
}
</script>

/*global $*/
/*eslint no-undef: "error"*/

export default class MenuProduct {
    classButton = 'tab--button'
    classHeader = 'header'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function (e) {
            let block = $(this).data('block')
            let heightHeader = $(`.${self.classHeader}`).height()
            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - heightHeader
                },
                500
            )
            e.preventDefault()
            return false
        })
    }
}
